<template>
  <div class="image-uploader-container">
    <b-badge variant="secondary" class="cursor-default" v-b-tooltip :title="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.minWidthTips')" v-if="checkSize" >{{$t('COMPONENTS.IMAGE_UPLOADER.LABEL.minWidth', { width })}}</b-badge>
    <b-badge variant="secondary" class="mx-1 cursor-default" v-b-tooltip :title="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.minHeightTips')" v-if="checkSize" >{{$t('COMPONENTS.IMAGE_UPLOADER.LABEL.minHeight', { height })}}</b-badge>
    <file-pond
      :id="id"
      :name="name"
      ref="pond"
      :allow-multiple="false"
      :accepted-file-types="acceptedFileTypes"
      :server="server"
      :files="files"
      :label-idle="btnLabel ? btnLabel : btnLabelText"
      :labelInvalidField="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.InvalidField')"
      :labelFileWaitingForSize="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileWaitingForSize')"
      :labelFileSizeNotAvailable="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileSizeNotAvailable')"
      :labelFileLoading="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileLoading')"
      :labelFileLoadError="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileLoadError')"
      :labelFileProcessing="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileProcessing')"
      :labelFileProcessingComplete="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileProcessingComplete')"
      :labelFileProcessingAborted="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileProcessingAborted')"
      :labelFileProcessingError="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileProcessingError')"
      :labelFileProcessingRevertError="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileProcessingRevertError')"
      :labelFileRemoveError="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.FileRemoveError')"
      :labelTapToCancel="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.TapToCancel')"
      :labelTapToRetry="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.TapToRetry')"
      :labelTapToUndo="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.TapToUndo')"
      :labelButtonRemoveItem="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonRemoveItem')"
      :labelButtonAbortItemLoad="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonAbortItemLoad')"
      :labelButtonRetryItemLoad="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonRetryItemLoad')"
      :labelButtonAbortItemProcessing="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonAbortItemProcessing')"
      :labelButtonRetryItemProcessing="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonRetryItemProcessing')"
      :labelButtonProcessItem="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ButtonProcessItem')"
      styleButtonRemoveItemPosition="left"
      styleButtonProcessItemPosition="right"
      styleLoadIndicatorPosition="left"
      styleProgressIndicatorPosition="left"
      :allowImageValidateSize="checkSize"
      :imageValidateSizeMinWidth="width"
      :imageValidateSizeMinHeight="height"
      :imageValidateSizeLabelImageSizeTooSmall="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ValidateSizeLabelImageSizeTooSmall')"
      :imageValidateSizeLabelImageSizeTooBig="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ValidateSizeLabelImageSizeTooBig')"
      :imageValidateSizeLabelExpectedMinSize="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ValidateSizeLabelExpectedMinSize')"
      :imageValidateSizeLabelExpectedMaxSize="$t('COMPONENTS.IMAGE_UPLOADER.LABEL.ValidateSizeLabelExpectedMaxSize')"
      :onerror="handleError"
      @removefile="handleRemoveFileEvent"
      @addfileprogress="handleHandleEvent('addfileprogress')"
      @processfilestart="handleHandleEvent('processfilestart')"
      @processfileprogress="handleHandleEvent('processfileprogress')"
      @processfileabort="handleHandleEvent('processfileabort')"
      @processfile="handleHandleEvent('processfile')"
      v-on:init="handleFilePondInit"/>
  </div>
</template>

<script>
  import {getToken} from "@c@/jwt.service";
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond';
  // Import FilePond plugins
  // Please note that you need to install these plugins separately
  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
  import {
    debugConsole
  } from "@c@/helpers/helpers";
  // Create component
  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageValidateSize);

  export default {
    name: "ImageUploader",
    components: { FilePond },
    props: {
      name: {
        type: String,
        default: 'file'
      },
      id: {
        type: String,
        default: 'image_uploader'
      },
      btnLabel: {
        type: String
      },
      uploadForm: {
        type: Object,
        default: ()=> { return {} }
      },
      acceptedFileTypes: {
        type: String,
        default: 'image/jpeg, image/png, image/jpg, image/svg+xml, image/gif'
      },
      checkSize: Boolean,
      width: {
        type: [Number, String],
        default: 1
      },
      height: {
        type: [Number, String],
        default: 1
      },
      value: {
        type: String,
        default: ()=> ''
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(v){
          if (!this.eventHasIncludes()){
            this.handleNewFile(v);
            this.$emit('input', v)
          }else{
            this.handleHandleEvent(null);
          }
        }
      },
    },
    computed: {
      params(){
        return Object.assign({
          name: this.name,
        }, this.uploadForm);
      },
      server(){
        return {
          url: this.url,
          process: {
            url: '/v1/manager/image-upload',
            method: 'POST',
            withCredentials: false,
            headers: this.headers,
            timeout: 7000,
            ondata: (formData) => {
              for ( let item in this.params ){
                formData.append(item, this.params[item]);
              }
              return formData;
            },
            onload: (response)=>{
              let data = JSON.parse(response);
              data.id = this.id;
              this.$emit('input', data.path);
              return this.$emit('success', data);
            },
            onerror: null,
          },
          load: {
            url: '/v1/manager/fetch?file=',
            withCredentials: false,
            headers: this.headers,
            // onerror: (response) => { console.log(response)}
          },
          fetch: {
            url: '/v1/manager/fetch?file=',
            withCredentials: false,
            headers: this.headers,
            onerror: (response) => { console.log(response.data)}
          },
          revert: {
            url: '/v1/manager/image-destroy',
            method: 'DELETE',
            withCredentials: false,
            headers: this.headers,
          },
        };
      },
    },
    data(){
      return {
        url: `${process.env.VUE_APP_API_URL}`,
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        files: [],
        eventName: null,
        btnLabelText: this.$t('COMPONENTS.IMAGE_UPLOADER.LABEL.BUTTON')
      };
    },
    mounted() {},
    methods: {
      handleFilePondInit: function() {
        debugConsole({message: 'FilePond has initialized'});
        this.$emit('init');
        this.handleNewFile(this.value);
      },
      handleError(e){
        setTimeout(()=>{
          // this.$refs.pond.removeFiles();
        }, 1200);
        return this.$emit('error', e);
      },
      handleRemoveFileEvent(){
        let id = this.id;
        this.$emit('removeFile', id);
        this.$emit('input', '');
      },
      handleHandleEvent(eventName = null){
        this.eventName = eventName;
      },
      handleNewFile(val = null, oldVal = null){
        if ( ! val || this.eventHasIncludes() ) return;
        if ( this.$refs.hasOwnProperty('pond') ){
          let { files } = this;
          if ( !files.length || files[0].source !== val ){
            this.files = [{
              source: val,
              status: 5,
              options: {
                type: 'local',
              }
            }];
          }
        }
      },
      eventHasIncludes(){
        return ['addfileprogress', 'processfilestart', 'processfileprogress', 'processfileabort', 'processfile'].includes(this.eventName);
      }
    }
  }
</script>

<style scoped>
  @import "../../../assets/css/filepond.css";
  @import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
